import * as moment from 'moment';
import { Component } from '@angular/core';
import { EditFieldComponent } from 'core-app/shared/components/fields/edit/edit-field.component';
import { InjectField } from 'core-app/shared/helpers/angular/inject-field.decorator';
import { TimezoneService } from 'core-app/core/datetime/timezone.service';

@Component({
  template: `
    <div class="hours-or-minutes-duration-edit-field__wrapper">
      <input type="number"
            step="0.01"
            class="inline-edit--field op-input"
            #input
            [attr.aria-required]="required"
            [ngModel]="formatterHours(value)"
            (ngModelChange)="value = parserHours($event, input)"
            [attr.required]="required"
            (keydown)="handler.handleUserKeydown($event)"
            [disabled]="inFlight"
            [id]="handler.htmlId" />

      / Minuten:
      <input type="number"
            step="1"
            class="inline-edit--field op-input"
            #inputMinutes
            [ngModel]="formatterMinutes(value)"
            (ngModelChange)="value = parserMinutes($event, inputMinutes)"
            (keydown)="handler.handleUserKeydown($event)"
            [disabled]="inFlight"
            [id]="handler.htmlId + '_minutes'" />
    </div>
  `,
})
export class HoursOrMinutesDurationEditFieldComponent extends EditFieldComponent {
  @InjectField() TimezoneService:TimezoneService;

  // This method is based on the OpenProject implementation in HoursDurationEditFieldComponent.
  public parserHours(value:any, input:any) {
    if (value == null && !input.validity.valid) {
      value = this.value || 0;
    }

    return moment.duration(value, 'hours');
  }

  public parserMinutes(value:any, input:any) {
    if (value == null && !input.validity.valid) {
      value = (this.value || 0)
      value = isNaN(value) ? null : value / 60;
    }

    return moment.duration(value, 'minutes');
  }

  public formatterHours(value:any) {
    return Number(moment.duration(value).asHours().toFixed(2));
  }

  public formatterMinutes(value:any) {
    return Number(moment.duration(value).asMinutes().toFixed(2));
  }

  protected parseValue(val:moment.Moment | null) {
    if (val === null) {
      return val;
    }

    let parsedValue;
    if (val.isValid()) {
      parsedValue = val.toISOString();
    } else {
      parsedValue = null;
    }

    return parsedValue;
  }
}
