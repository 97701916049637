import {
  APP_INITIALIZER,
  Injector,
  NgModule,
} from '@angular/core';

import './global_scripts';
import { UIRouterModule } from '@uirouter/angular';
import { HookService } from 'core-app/features/plugins/hook-service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EditFieldService } from 'core-app/shared/components/fields/edit/edit-field.service';
import { HoursOrMinutesDurationEditFieldComponent } from 'core-app/features/plugins/linked/openproject-time_entry_minutes/form-components/hours-or-minutes-duration-edit-field.component';

export function initializeTimeEntryMinutesPlugin(injector:Injector, editFieldService:EditFieldService) {
  return () => {
    editFieldService.addSpecificFieldType('TimeEntry', HoursOrMinutesDurationEditFieldComponent, 'hours', ['hours'])
  };
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTimeEntryMinutesPlugin,
      deps: [
        Injector,
        EditFieldService,
      ],
      multi: true,
    },
  ],
  declarations: [
    HoursOrMinutesDurationEditFieldComponent,
  ],
})

export class PluginModule {
}
